<template>
  <div class="guide">
    <b-tabs content-class="px-2" align="center">
      <b-tab title="Mới cập nhật" active>
        <ul style="padding-left: 20px">
          <li>
            <code style="margin-left: -15px"
              >1. Cú pháp đài chính, đài phụ cho miền trung:</code
            >
            <p style="text-align: justify">
              Cược miền trung quý khách chọn Miền Trung và ghi dc, dp, ko cần
              ghi rõ tên đài.<br />
            </p>
          </li>
          <li>
            <code style="margin-left: -15px">2. Chỉnh cò lẻ:</code>
            <p style="text-align: justify">
              Có thể chỉnh cò lẻ, ví dụ 75.56<br />
            </p>
          </li>
          <li>
            <code style="margin-left: -15px">
              3. Thay đổi thứ tự dc, dp Miền Nam:
            </code>
            <p style="text-align: justify">
              Cú pháp dc,dp nhận theo thứ tự bạn chọn.<br />
              <code>Ví dụ:</code><br />
              - Chủ Nhật: khách ở TP, dc là TG, dp là KG <br />
              - Nhưng khách ở Kiên Giang, dc là KG, dp là TG<br />
              - Bạn có thể chỉnh được cho phù hợp với từng khách.<br />
            </p>
          </li>
          <li>
            <code style="margin-left: -15px">4. Quản lý tin:</code>
            <p style="text-align: justify">
              Thay vì nhập và sửa từng tin, bạn có thể nhập tất cả các tin dù
              đúng hay sai thì hệ thống cũng sẽ nhận.<br />
              Sau khi nhập xong hệ thống sẽ báo cho bạn biết tin dó đúng hay
              sai.<br />
              Nếu tin sai thì bạn ấn nút sửa và lưu lại, sẽ lưu cả tin gốc ban
              đầu và tin bạn đã sửa để so sánh.<br />
            </p>
          </li>
          <li>
            <code style="margin-left: -15px"
              >5. Cài đặt kiểu cú pháp riêng cho từng khách hàng:</code
            >
            <p style="text-align: justify">
              Vào khách hàng / cấu hình và chọn kiểu cú pháp (đài số kiểu, số
              kiểu đài,...) riêng cho từng khách hàng
            </p>
          </li>
          <li>
            <code style="margin-left: -15px">6. Cú pháp:</code>
            <p style="text-align: justify">
              - bd sẽ nhập là Bình Dương vào thứ 6, các ngày khác là baodao<br />
              - baodao bạn sẽ dùng "db,baodao,dbl,bld,bdao,dbao,db" và bd (trừ
              thứ 6)<br />
              - cú pháp dx sẽ tự động nhận 2 đài chính nếu bạn gõ dc, dp hoặc
              tên 1 trong 2 đài chính ngày hôm đó<br />
            </p>
          </li>
          <li>
            <code style="margin-left: -15px"
              >7. Tính năng cảnh báo (báo số dư):</code
            >
            <p style="text-align: justify">
              Thông báo danh sách các số đánh vượt quá giới hạn so với cài đặt
              ban đầu của bạn.<br />
              Chỉ khách hàng nào bật cảnh báo thì mới tính.<br />
              <code>Cài đặt:</code><br />
              - Vào mục khách hàng/cầu hình chọn bật cảnh báo để kích hoạt tính
              cho khách đó<br />
              - Vào mục cài đặt/cảnh báo để nhập giới hạn mà bạn muốn cảnh
              báo<br />
            </p>
          </li>
          <li>
            <code style="margin-left: -15px">8. Tài khoản phụ:</code>
            <p style="text-align: justify">
              Bạn có thể tạo thêm các tài khoản phụ (tài khoản sub) đẻ cùng xem
              báo cáo những tin bạn đã nhập.
            </p>
          </li>
          <li>
            <code style="margin-left: -15px"
              >9. Đã có thể đá xiên miền Trung!</code
            >
          </li>
          <li>
            <code style="margin-left: -15px"
              >10. Cú pháp dx tự hiểu đảo xỉu chủ nếu là số 3c:</code
            >
            <p style="text-align: justify">
              <code>Ví dụ:</code><br />
              123dx10 = 123dxc10
            </p>
          </li>
        </ul>
      </b-tab>
      <b-tab title="Hướng dẫn">
        <p>Có nhiều <code>cú pháp</code> với các kiểu nhận tin khác nhau</p>
        <p>
          <code>Lưu ý:</code> TẤT CẢ TIN NHẮN KHÔNG PHẢI XUỐNG HÀNG KHI KẾT THÚC
          CƯỢC
        </p>
        <ul style="padding-left: 15px; padding-bottom: 0">
          <li style="padding: 5px 0">
            <code>1. Đài-số-kiểu</code><br />
            - Nhập tên đài trước, số muốn đánh, kiểu đánh, số điểm.
            <p>
              <code>Ví dụ:</code><br />
              Dc 52.70 da60. 938 xc150. 779 xc300 xd40. 277xc130 xd10. 573 x240
              xd3. 73 dau300 dui200n. 425.119 xc130 xd10. dp 119x20. 779.277
              xc200 xd10
            </p>
          </li>
          <li style="padding: 5px 0">
            <code>2. Số-kiểu-đài</code><br />
            - Nhập số muốn đánh trước, kiểu đánh, số điểm, tên đài.
            <p>
              <code>Ví dụ:</code><br />
              52.70 da60. 938 xc150. 779 xc300 xd40. 277xc130 xd10. 573 x240
              xd3. 73 dau300 dui200n. 425.119 xc130 xd10. dp 119x20. 779.277
              xc200 xd10 Dc
            </p>
          </li>
          <li style="padding: 5px 0">
            <code>3. Đài-số-kiểu-AB</code> <br />
            - Nhập tên đài trước, số muốn đánh, kiểu đánh, số điểm. <br />
            - Tương tự như kiểu 1 nhưng chấp nhận a đầu, b đuôi, ab đầu đuôi.
            <p>
              <code>Ví dụ:</code><br />
              Dc 79a10 32ab20
            </p>
          </li>
          <li style="padding: 5px 0">
            <code>4. Số-kiểu-đài-AB</code> <br />
            - Nhập số trước, kiểu muốn đánh, số điểm, đài.<br />
            - Tương tự như kiểu 2 nhưng chấp nhận a đầu, b đuôi, ab đầu đuôi.
            <p>
              <code>Ví dụ:</code><br />
              79a10 32ab20 Dc
            </p>
          </li>
        </ul>
        <p>
          <span class="m-badge m-badge--wide m-badge--danger m-badge--rounded"
            >CHÚ Ý:</span
          >&nbsp;&nbsp; Nhập đúng đài và kiểu muốn cược theo tên đã định nghĩa ở
          menu bên cạnh
        </p>
      </b-tab>
      <b-tab title="Đặc biệt">
        <ul style="padding-left: 15px; padding-bottom: 0">
          <li style="padding: 5px 0">
            <code
              >Tên đài, kiểu đánh có thể nhập tiếng việt có dấu hoặc không
              dấu</code
            >
            <p>
              <code>Ví dụ:</code><br />
              Chánh 34 đầu 20 ngàn, chanh34dau20n sẽ cho kết quả như nhau
            </p>
          </li>
          <li style="padding: 5px 0">
            <code>Điểm cược</code><br />
            - n, ng, ngan, ngàn: 10n, 10ng hệ thống hiểu 10<br />
            - trieu, triệu: tn11dau10trieu hệ thống sẽ hiểu là 10,000
          </li>
          <li style="padding: 5px 0">
            <code>Số cược</code><br />
            - keo, kéo, den, đến: 10đến 90 hệ thống sẽ hiểu là:
            10,20,30,40,50,60,70,80,90<br />
            - lonchuc, nhochuc, ledonvi, chandonvi, nhole, lenho, nhochan,
            channho, lonle, lonchan, lele, lechan, chanchan, chanle, congiap,
            lechuc, chanchuc
          </li>
          <li style="padding: 5px 0">
            <code>Tên đài</code><br />
            - 4d, 4dai, 3d, 3dai, 2d, 2dai, dc, dp, chanh, chinh, phu, dchanh,
            dchinh, dphu, daichanh, daiphu, dch
          </li>
          <li style="padding: 5px 0">
            <code>Cú pháp bd</code><br />
            - sẽ nhập là Bình Dương vào thứ 6, các ngày khác là bao đảo
          </li>
          <li style="padding: 5px 0">
            <code>da, dv, dav nếu có 2 đài hệ thống sẽ hiểu là đá xiên</code>
            <p>
              <code>Ví dụ:</code><br />
              vl.sbe45.54da20 hệ thống sẽ hiểu là đá xiên: vl.sbe45.54dx20
            </p>
          </li>
          <li style="padding: 5px 0">
            <code>Cú pháp dx tự hiểu đảo xỉu chủ nếu là số 3c</code>
            <p>
              <code>Ví dụ:</code><br />
              123dx10 hệ thống sẽ hiểu là đá xiên: 123dxc10
            </p>
          </li>
          <li style="padding: 5px 0">
            <code>Tự thêm đài đá xiên khi có 1 đài</code><br />
            <p>
              <code>Ví dụ: Hôm nay có 3 đài: Thành phố, Đồng Tháp, Cà Mau</code
              ><br />
              - tp12.21dx10 sẽ là: tp.dt12.21dx10<br />
              - dt12.21dx10 sẽ là: tp.dt12.21dx10<br />
              - dc12.21dx10 sẽ là: tp.dt12.21dx10<br />
              - dp12.21dx10 sẽ là: tp.dt12.21dx10<br />
            </p>
          </li>
          <li style="padding: 5px 0">
            <code>Bao, bao đảo liên tục</code>
            <p>
              <code>Ví dụ:</code><br />
              vt1234b1db1b2db2b3db3 hệ thống sẽ hiểu: vt bao 1n số 1234, vt bao
              đảo 1n số 1234, vt bao 2n số 234, vt bao đảo 2n số 234, vt bao 3n
              số 34, vt bao đảo 3n số 34
            </p>
          </li>
        </ul>
      </b-tab>
      <b-tab title="Tên đài">
        <b-table striped hover :items="channels"></b-table>
      </b-tab>
      <b-tab title="Tên kiểu">
        <b-table striped hover :items="props"></b-table>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Guide",
  data() {
    return {
      channels: [
        { name: "Miền Bắc", code: "hn, mb, hanoi" },
        { name: "Thừa T. Huế", code: "tth" },
        { name: "Phú Yên", code: "py" },
        { name: "Đắk Lắk", code: "dl" },
        { name: "Quảng Nam", code: "qn, quangnam" },
        { name: "Đà Nẵng", code: "dng" },
        { name: "Khánh Hòa", code: "kh" },
        { name: "Bình Định", code: "bdi" },
        { name: "Quảng Trị", code: "qt" },
        { name: "Quảng Bình", code: "qb" },
        { name: "Gia Lai", code: "gl" },
        { name: "Ninh Thuận", code: "nt" },
        { name: "Quảng Ngãi", code: "qn, quangngai" },
        { name: "Đắk Nông", code: "dno" },
        { name: "Kon Tum", code: "kt" },
        { name: "Kon Tum", code: "kt" },
        { name: "Hồ Chí Minh", code: "tp, hcm" },
        { name: "Đồng Tháp", code: "dt" },
        { name: "Cà Mau", code: "cm, camau" },
        { name: "Bến Tre", code: "bt, btr, btre" },
        { name: "Vũng Tàu", code: "vt" },
        { name: "Bạc Liêu", code: "blieu, baclieu" },
        { name: "Đồng Nai", code: "dn" },
        { name: "Cần Thơ", code: "ct" },
        { name: "Sóc Trăng", code: "st, soctrang" },
        { name: "Tây Ninh", code: "tn" },
        { name: "An Giang", code: "ag" },
        { name: "Bình Thuận", code: "bt, binhthuan" },
        { name: "Vĩnh Long", code: "vl" },
        { name: "Bình Dương", code: "sbe, bd, sb" },
        { name: "Trà Vinh", code: "tv, travinh" },
        { name: "Long An", code: "la" },
        { name: "Hậu Giang", code: "hg, haugiang" },
        { name: "Tiền Giang", code: "tg" },
        { name: "Kiên Giang", code: "kg" },
        { name: "Đà Lạt", code: "dl, dalat" },
        { name: "Bình Phước", code: "bp, binhphuoc" }
      ],
      props: [
        { name: "Đầu", code: "dau, a" },
        { name: "Đuôi", code: "duoi, dui" },
        { name: "Đuôi AB", code: "duoi, dui, b" },
        { name: "Đầu đuôi", code: "dd, dauduoi, daudui, ab" },
        { name: "Bao lô", code: "b, bao, bl, blo, lo, doc" },
        { name: "Bao lô AB", code: "bao, bl, blo, lo" },
        {
          name: "Bao lô đảo thứ 6",
          code: "baodao, daolo, dlo, bld, bdao, dbao, db, blodao, dbl, bldao"
        },
        {
          name: "Bao lô đảo",
          code:
            "baodao, daolo, dlo, bld, bdao, dbao, db, bd, blodao, dbl, bldao"
        },
        { name: "Bảy lô", code: "slo, baylo" },
        { name: "Bảy lô đảo", code: "slodao, daoslo, daobaylo, baylodao" },
        { name: "Xỉu chủ", code: "xc, x, tl, tlo, sc, siu" },
        { name: "Xỉu chủ đầu", code: "xdau, xcdau, tldau, tlodau" },
        {
          name: "Xỉu chủ đuôi",
          code: "xduoi, xcdui, xcduoi, xdui, tldui, tlduoi, tlodui, tloduoi"
        },
        {
          name: "Xỉu chủ đảo",
          code:
            "xd, xcd, dxc, daox, daoxc, xdao, xcdao, tld, dtl, daotl, tldao, tlod, dtlo, daotlo, tlodao, suidao"
        },
        {
          name: "Xỉu chủ đảo đầu",
          code:
            "xdaudao, xddau, daoxdau, xcdaudao, daoxcdau, tldaudao, daotldau, tlduidao, tlodaudao, daotlodau"
        },
        {
          name: "Xỉu chỉ đảo đuôi",
          code:
            "xduoidao, xduidao, xddui, xdduoi, daoxdui, daoxduoi, xcduidao, xcduoidao, daoxcdui, daoxcduoi, tlduoidao, daotldui, daotlduoi, tloduidao, tloduoidao, daotlodui, daotloduoi"
        },
        { name: "Đá", code: "da" },
        { name: "Đá thẳng", code: "dat" },
        { name: "Đá vòng", code: "dv, dav" },
        { name: "Đá xiên xỉu đảo", code: "dx" },
        { name: "Đá xiên", code: "dx, dax, dxien, daxien, cheo" },
        { name: "Đá xiên vòng", code: "dxv, daxv, dvx" }
      ]
    };
  },
  components: {},
  created() {
    // this.showMsgOk();
  },
  methods: {
    showMsgOk() {
      const h = this.$createElement;
      const titleVNode = h("div", {
        domProps: { innerHTML: "Hệ thống cập nhật xoá tin tự động." }
      });
      const messageVNode = h("div", { class: ["foobar px-3"] }, [
        h("p", { class: ["text-left"] }, [
          "Quý khách vào cài đặt, chọn số tuần muốn giữ lại tin."
        ]),
        h("p", { class: ["text-left pl-2"] }, [
          "- Giữ tin tuần trước: Hệ thống sẽ giữ lại tin của 1 tuần trước đó, còn lại sẽ tự động xoá vào 23h59 thứ 2."
        ]),
        h("p", { class: ["text-left pl-2"] }, [
          "- Giữ tin 2 hoặc 3 tuần trước: Hệ thống sẽ giữ lại tin của 2 hoặc 3 tuần trước đó, còn lại sẽ tự động xoá vào 23h59 thứ 2."
        ]),
        h("p", { class: ["text-left pl-2"] }, [
          "- Đặc biệt chương trình khuyến mãi khi gia hạn từ ngày 03/09 như sau:"
        ]),
        h("p", { class: ["text-left text-danger"] }, [
          "Mặc định hệ thống giữ lại 2 tuần trước đó."
        ]),
        h("p", { class: ["text-left"] }, [
          "Tin đã xoá tự động là xoá VĨNH VIỄN và KHÔNG CÓ CÁCH NÀO KHÔI PHỤC được. "
        ]),
        h("p", { class: ["text-left"] }, [
          "Xin quý khách lưu ý tuỳ chọn phù hợp. Xin cảm ơn!"
        ])
      ]);
      this.$bvModal.msgBoxOk([messageVNode], {
        title: [titleVNode],
        hideHeaderClose: false
      });
    }
  }
};
</script>
<style>
.guide {
  text-align: justify;
}
.guide ul {
  list-style-type: none;
}
.guide .table th,
.guide .table td {
  border-top: 0;
}
</style>
